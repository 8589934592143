import type { SelectChangeEvent } from '@mui/material';
import { Box, MenuItem, Select, styled } from '@mui/material';
import type { SortingOptionLabel } from '../api';
import useListParams from 'hooks/useListParams';
import { useCreativeSortingParam } from '../hooks/useCreativeSortingParam';

const FilterSortingContainer = styled(Box)(
  ({ theme: t }) => `
  ${t.breakpoints.up('sm')} {
    padding-left: 12px;
  };

  ${t.breakpoints.down('sm')} {
    padding-left: 12px;
    width: 100%;
  };
`
);
interface Option {
  value: boolean;
  label: SortingOptionLabel;
}

const SORTING_OPTIONS: Option[] = [
  { value: true, label: 'Newest' },
  { value: false, label: 'Oldest' },
];

const AssetsSorting = () => {
  const [orderBy] = useCreativeSortingParam();
  const { desc, setDesc, setOrderBy } = useListParams();

  const handleSorting = (event: SelectChangeEvent) => {
    setDesc(event.target.value === 'true');
    setOrderBy('created_at');
  };

  const selectedValue = orderBy !== 'created_at' ? 'undefined' : `${desc}`;

  return (
    <FilterSortingContainer>
      <Select value={selectedValue} onChange={handleSorting}>
        <MenuItem value={'undefined'} disabled>
          Sorting by
        </MenuItem>
        {SORTING_OPTIONS.map((option) => (
          <MenuItem key={option.label} value={`${option.value}`}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FilterSortingContainer>
  );
};

export default AssetsSorting;
