import useListParams from 'hooks/useListParams';
import { usePagination } from 'hooks';
import { useCreativeSortingParam } from './useCreativeSortingParam';
import { DEFAULT_PER_PAGE } from '../constants';

const useCreativeQueryParams = () => {
  const { page, perPage } = usePagination([], DEFAULT_PER_PAGE);
  const { desc, tags, q, condition } = useListParams();
  const [orderBy] = useCreativeSortingParam();

  return {
    limit: perPage,
    offset: page * perPage,
    desc,
    order_by: String(orderBy),
    q,
    tags: tags as number[],
    condition,
  };
};

export default useCreativeQueryParams;
