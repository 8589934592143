import { Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import type { CreativeTableItem } from '../api/types';
import type { CreativeTag } from '../api/types';
import CreativesTableRowActions from './CreativeTableRowActions';
import { COLUMNS } from '../constants';
import { useFormContext } from 'react-hook-form';
import type { CreativesSettingsState } from '../types';
import getOrderedColumnsToDisplay from '../utils/getOrderedColumnsToDisplay';
import { useEffect, useState } from 'react';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import CreativesFilesButton from './CreativesFilesButton';
import FileIcon from '../../../components/common/FileIcon';
import { useCreativeFilesSync } from '../api/getCreativeFiles';
import { debounce, isEmpty } from 'lodash-es';
import { format } from 'date-fns';

interface CreativeTableRowProps {
  item: CreativeTableItem;
  openPreview: (index: number) => void;
  index: number;
}

const displayTagsMapper = (t: CreativeTag) => t.name;

// like an instance of the Factory to extend in the future if needed
const displayValueFormatter = (column: keyof CreativeTableItem, value: string | number) => {
  if (column === 'finish_date' && !isEmpty(value)) return format(new Date(value), 'dd.MM.yyyy');
  return value;
};
const CreativeTableRow: FC<CreativeTableRowProps> = ({ item, openPreview, index }) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false);
  const [isPreviewHovered, setIsPreviewHovered] = useState(false);
  const [isCreativeFilesError, setIsCreativeFilesError] = useState(false);
  const [isRowHovered, setIsRowHovered] = useState(false);

  const showFallbackIcon = !item.thumbnail || isImageLoadError;

  const { watch } = useFormContext<CreativesSettingsState>();
  const columns = watch('columns');
  const order = watch('columnsOrder');
  const orderedColumnsToDisplay = getOrderedColumnsToDisplay(columns, order);

  const previewSize = watch('previewSize') === 'large' ? 96 : 64;

  const { isError: creativeFilesError } = useCreativeFilesSync({
    params: { id: item.id },
    config: { enabled: isRowHovered && !isCreativeFilesError },
  });

  useEffect(() => {
    if (creativeFilesError) {
      setIsCreativeFilesError(true);
    }
  }, [creativeFilesError]);

  const handleAssetFilesSync = debounce(() => {
    setIsRowHovered(true);
  }, 300);

  return (
    <TableRow
      onMouseLeave={() => {
        handleAssetFilesSync.cancel();
        setIsRowHovered(false);
      }}
      onMouseEnter={() => {
        handleAssetFilesSync();
      }}
      onClick={() => {
        open(`/creatives/${item.id}`, '_blank');
      }}
      sx={(t) => ({
        '&:hover': {
          td: {
            background: t.palette.grey[300],
          },
        },
        td: {
          border: '1px solid',
          borderColor: t.palette.grey[200],
          p: 1,
          minWidth: 108,
          maxWidth: 108,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          cursor: 'pointer',
        },
        'td:nth-child(2)': {
          borderLeft: 'none !important',
        },
      })}
    >
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
          openPreview(index);
        }}
        onMouseLeave={() => {
          setIsPreviewHovered(false);
        }}
        onMouseEnter={() => {
          setIsPreviewHovered(true);
        }}
        sx={{
          p: '1px !important',
          position: 'relative',
          minWidth: `${previewSize}px !important`,
          maxHeight: `${previewSize}px !important`,
        }}
      >
        {isPreviewHovered && (
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <VisibilityIcon sx={{ fontSize: 34, color: showFallbackIcon ? 'gray' : 'white' }} />
          </Box>
        )}
        {showFallbackIcon ? (
          <FileIcon
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: -1,
            }}
            type={item.media_type}
          />
        ) : (
          <Box
            sx={{
              display: 'block',
              left: '50%',
              top: '50%',
            }}
            component="img"
            onError={() => {
              setIsImageLoadError(true);
            }}
            width={previewSize}
            height={previewSize}
            src={item.thumbnail}
          />
        )}
      </TableCell>
      {orderedColumnsToDisplay.map((columnKey) => {
        const { apiKey } = COLUMNS[columnKey];
        const value = item[apiKey as keyof CreativeTableItem] as string | number | CreativeTag[];
        const displayValue = Array.isArray(value)
          ? value.map(displayTagsMapper)
          : displayValueFormatter(apiKey as keyof CreativeTableItem, value);
        const tooltipValue = columnKey === 'file_name' && displayValue.toString().length > 38 ? displayValue : null;

        return (
          <TableCell key={apiKey}>
            <Tooltip placement="top" arrow title={tooltipValue}>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {displayValue}
              </Typography>
            </Tooltip>
          </TableCell>
        );
      })}
      <TableCell align="right">
        <CreativesFilesButton creativeId={item.id} variant="text" />
        <CreativesTableRowActions creativeId={item.id} name={item.file_name} />
      </TableCell>
    </TableRow>
  );
};

export default CreativeTableRow;
