import { useEffect } from 'react';
import type { UrlUpdateType } from 'use-query-params';
import { createEnumParam, useQueryParam, withDefault } from 'use-query-params';
import { useFormContext } from 'react-hook-form';
import type { CreativesSettingsState, FilterSortingKey } from '../types';
import type { ColumnKey } from '../types';
import { COLUMNS } from '../constants';

type SortingParams = ColumnKey | FilterSortingKey;
const SORTING_PARAMS = [...Object.keys(COLUMNS), 'created_at'] as SortingParams[];
export const CREATIVE_ORDER_PARAM = withDefault(createEnumParam<SortingParams>(SORTING_PARAMS), 'created_at');

type QueryParamsNewValueType<D> = D | ((latestValue: D) => D);
export function useCreativeSortingParam(): [
  SortingParams | null | undefined,
  (newValue: QueryParamsNewValueType<SortingParams | null | undefined>, updateType?: UrlUpdateType) => void,
] {
  const { watch } = useFormContext<CreativesSettingsState>();
  const columns = watch('columns');
  const visibleCols = (Object.keys(columns) as SortingParams[]).filter((k) => columns[k as ColumnKey]);

  const [orderBy, setOrderBy] = useQueryParam('order_by', CREATIVE_ORDER_PARAM);
  useEffect(() => {
    if (!orderBy || ![...visibleCols, 'created_at'].includes(orderBy)) {
      const newOrderBy: SortingParams =
        (Object.keys(columns) as SortingParams[]).find((key: SortingParams) => visibleCols.includes(key)) ??
        'created_at';

      setOrderBy(newOrderBy as SortingParams);
    }
  }, [orderBy, visibleCols, columns]);

  return [orderBy, setOrderBy];
}
