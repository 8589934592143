import { type FC } from 'react';
import { Box, Table, TableRow, TableHead, TableContainer, TableCell, TableBody, Skeleton, Stack } from '@mui/material';
import { useBreakpoints } from 'hooks';

const TagGroupsListSkeleton: FC<{ tagGroupIdDetails: number | null }> = ({ tagGroupIdDetails }) => {
  const { isBreakpoint } = useBreakpoints('sm');

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', flexGrow: 1, width: '100%', position: 'relative' }}>
        <TableContainer component={Box} sx={{ position: 'absolute', top: 0, left: 0, height: '100%' }}>
          <Table size="medium" stickyHeader sx={{ '& thead th': { backgroundColor: 'white' } }}>
            <TableHead sx={{ ['th']: { padding: 0 } }}>
              <TableRow>
                <TableCell>Name</TableCell>
                {!tagGroupIdDetails && (
                  <>
                    <TableCell>Visibility</TableCell>
                    <TableCell>Tags count</TableCell>
                    {!isBreakpoint ? <TableCell>Tags preview</TableCell> : null}
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(15).keys()].map((i) => (
                <TableRow sx={{ paddingY: 2, height: 71, minHeight: 71, ['td']: { padding: 0 } }} key={i}>
                  <TableCell sx={{ minWidth: 160 }}>
                    <Skeleton animation="wave" height={30} width={140} />
                  </TableCell>
                  {!tagGroupIdDetails && (
                    <>
                      <TableCell sx={{ minWidth: 80 }}>
                        <Skeleton animation="wave" height={30} width={30} />
                      </TableCell>
                      <TableCell sx={{ minWidth: 80 }}>
                        <Skeleton animation="wave" height={30} width={30} />
                      </TableCell>
                      {!isBreakpoint ? (
                        <TableCell>
                          <Stack direction={'row'} gap={1}>
                            <Skeleton animation="wave" height={40} width={160} />
                          </Stack>
                        </TableCell>
                      ) : null}
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', py: 1, px: 2 }}>
        <Skeleton animation="wave" height={40} width={400} />
      </Box>
    </Box>
  );
};

export default TagGroupsListSkeleton;
