import {
  BooleanParam,
  NumberParam,
  NumericArrayParam,
  StringParam,
  createEnumParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';

const useListParams = () => {
  const [orderBy, setOrderBy] = useQueryParam('order_by', withDefault(createEnumParam(['created_at']), 'created_at'));
  const [desc, setDesc] = useQueryParam('desc', withDefault(BooleanParam, true));
  const [favourite, setFavourite] = useQueryParam('favourite', withDefault(BooleanParam, false));
  const [tags, setTags] = useQueryParam('tags', withDefault(NumericArrayParam, undefined));
  const [q, setQ] = useQueryParam('q', withDefault(StringParam, undefined));
  const [condition, setCondition] = useQueryParam('condition', withDefault(StringParam, undefined));
  const [previewId, setPreviewId] = useQueryParam('preview', withDefault(NumberParam, undefined));
  const [type, setType] = useQueryParam('type', withDefault(StringParam, 'assets'));

  return {
    orderBy,
    setOrderBy,

    desc,
    setDesc,

    favourite,
    setFavourite,

    tags,
    setTags,

    q,
    setQ,

    previewId,
    setPreviewId,

    condition,
    setCondition,

    type,
    setType,
  };
};

export default useListParams;
