import { usePagination } from 'hooks';
import useListParams from 'hooks/useListParams';

const useQueryParam = () => {
  const { page, perPage, handlePageChange, handleRowsPerPageChange } = usePagination([], 50);
  const { type } = useListParams();
  return { page, perPage, handlePageChange, handleRowsPerPageChange, type: type !== 'all' ? type : undefined };
};

export default useQueryParam;
