import { Box, Tab, Tabs } from '@mui/material';
import useListParams from 'hooks/useListParams';
import type { FC, SyntheticEvent } from 'react';

const FilterTypes: FC = () => {
  const { type, setType } = useListParams();
  const handleChange = (_: SyntheticEvent, value: string) => {
    setType(value);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', width: '100%', maxHeight: 56, minHeight: 56 }}>
      <Tabs value={type} onChange={handleChange}>
        <Tab label="Assets" value={'assets'} />
        <Tab label="Creatives" value={'creatives'} />
        <Tab label="All" value={'all'} />
      </Tabs>
    </Box>
  );
};

export default FilterTypes;
