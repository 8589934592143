import type { CreativeDetailsModelKeys } from './api/types';
import type { ColumnKey, ColumnValue, PreviewOption, AccessOption } from './types';

export const DEFAULT_PER_PAGE = 25;

export const PREVIEW_OPTIONS: PreviewOption[] = [
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
];

export const COLUMNS: Record<ColumnKey, ColumnValue> = {
  file_name: { apiKey: 'file_name', title: 'File name' },
  creative_id: { apiKey: 'creative_id', title: 'ID' },
  base_id: { apiKey: 'base_id', title: 'Base ID' },
  creative_type: { apiKey: 'creative_type', title: 'Creative type' },
  idea_source: { apiKey: 'idea_source', title: 'Idea source' },
  idea_type: { apiKey: 'idea_type', title: 'Idea type' },
  social_network: { apiKey: 'social_network', title: 'Social network' },
  platform: { apiKey: 'platform', title: 'Platform' },
  branch: { apiKey: 'branch', title: 'Branch' },
  content_type: { apiKey: 'content_type', title: 'Content type' },
  technology: { apiKey: 'technology', title: 'Technology' },
  resolution: { apiKey: 'resolution', title: 'Resolution' },
  gender: { apiKey: 'gender', title: 'Gender' },
  languages: { apiKey: 'languages', title: 'Languages' },
  policy_check: { apiKey: 'policy_check', title: 'Policy check' },
  project_name: { apiKey: 'project_name', title: 'Project name' },
  customer_name: { apiKey: 'customer_name', title: 'Customer name' },
  performer: { apiKey: 'performer', title: 'Performer' },
  model_name: { apiKey: 'model_name', title: 'Model name' },
  model_video_id: { apiKey: 'model_video_id', title: 'Model video Id' },
  finish_date: { apiKey: 'finish_date', title: 'Finish date' },
  spend: { apiKey: 'spend', title: 'Spend' },
  roi: { apiKey: 'roi', title: 'ROI, %' },
  purchases: { apiKey: 'purchases', title: 'Purchases' },
};

export const INITIAL_COLUMN_ORDER = Object.keys(COLUMNS).map((k) => ({
  name: `columns.${k}`,
  title: String(COLUMNS[k as ColumnKey].title),
}));

export const CREATIVE_DETAILS_FIELD: Record<CreativeDetailsModelKeys, string> = {
  creative_id: 'ID',
  id: 'ID',
  file_name: 'File Name',
  thumbnail: 'Thumbnail',
  media_link: 'Media Link',
  download_files_link: 'Download Files',
  branch: 'Branch',
  technology: 'Technology',
  resolution: 'Resolution',
  gender: 'Gender',
  languages: 'Languages',
  policy_check: 'Policy Check',
  projects: 'Project Name',
  tags: 'Tags',
  customer: 'Customer',
  base_customer: 'Base Customer',
  performer: 'Performer',
  model_name: 'Model Name',
  model_video_id: 'Model Video ID',
  access: 'Access',
  original_creative_asset: 'Original Creative',
  base_id: 'Base ID',
  creative_type: 'Creative Type',
  idea_source: 'Idea Source',
  idea_type: 'Idea Type',
  social_network: 'Social Network',
  comment: 'Comment',
  original_text: 'Original Text',
  media_type: 'Media Type',
  spend: 'Spend',
  roi: 'ROI, %',
  purchases: 'Purchases',
};

export const ACCESS_OPTIONS: AccessOption[] = [
  { value: true, label: 'Available to all' },
  { value: false, label: 'Restricted' },
];
