import { Box } from '@mui/material';
import FilterCondition from './FilterCondition';
import FilterSorting from './FilterSorting';
import CreativesCounter from './CreativesCounter';

const Filters = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <FilterCondition />
      <Box sx={{ display: 'flex', justifyContent: 'end', flexWrap: 'nowrap' }}>
        <CreativesCounter />
        <FilterSorting />
      </Box>
    </Box>
  );
};

export default Filters;
