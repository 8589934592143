import { Box } from '@mui/material';
import type { FC } from 'react';
import CreativesSearchValues from './CreativesSearchValues';
import Filters from './Filters';

const CreativesTopPanel: FC = () => {
  return (
    <Box
      sx={(t) => ({
        pr: 1,
        py: 2,
        gap: 2,
        zIndex: 4,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        [t.breakpoints.up('md')]: {
          minHeight: 60,
        },
      })}
    >
      <Filters />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <CreativesSearchValues />
      </Box>
    </Box>
  );
};

export default CreativesTopPanel;
