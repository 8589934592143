import { Box, Button, Typography } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import type { FC, ReactElement} from 'react';
import { useCallback, useRef } from 'react';
import useListParams from 'hooks/useListParams';

const TextHint: FC<{ text?: string }> = ({ text }) => {
  return (
    <Typography
      variant="caption"
      sx={(t) => ({
        minHeight: 24,
        gap: 0.5,
        display: 'flex',
        alignItems: 'center',
        color: t.palette.text.secondary,
      })}
    >
      {text && <InfoOutlinedIcon sx={(t) => ({ fontSize: t.typography.subtitle1.fontSize })} />}
      {text}
    </Typography>
  );
};

const FilterCondition = () => {
  const { condition, setCondition } = useListParams();
  const listFilters = useRef<{ icon: ReactElement; label: string; hint?: string; value: string }[]>([
    {
      label: 'All',
      value: 'all',
      icon: <ImageOutlinedIcon sx={{ fontSize: 21 }} />,
      hint: 'Metrics period: end of MM test',
    },
    {
      label: 'UA prod',
      value: 'ua_prod',
      icon: <MonetizationOnOutlinedIcon sx={{ fontSize: 21 }} />,
      hint: 'Prod creatives that had spend over the last 7 days',
    },
    {
      label: 'Favourites',
      value: 'favourites',
      icon: <StarOutlinedIcon sx={{ fontSize: 21 }} />,
    },
  ]);

  const handleCondition = useCallback(
    (value: string) => {
      setCondition(value !== 'all' ? value : undefined);
    },
    [setCondition]
  );
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 1 }}>
        {listFilters.current.map((item) => (
          <Button
            key={`creativesFilters:${item.value}`}
            variant="contained"
            size="small"
            color={condition === item.value || (!condition && item.value === 'all') ? 'primary' : 'info'}
            startIcon={item.icon}
            sx={(t) => ({ height: 24, borderRadius: t.borderRadius.sm, textTransform: 'initial' })}
            onClick={() => { handleCondition(item.value); }}
          >
            {item.label}
          </Button>
        ))}
      </Box>
      <TextHint text={listFilters.current.find((item) => item.value === (condition ?? 'all'))?.hint} />
    </Box>
  );
};

export default FilterCondition;
