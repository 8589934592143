import { Table, TableBody, TableContainer, Typography } from '@mui/material';
import CreativeTableHead from './CreativeTableHead';
import CreativeTableRow from './CreativeTableRow';
import { TablePagination } from '@mui/material';
import { useModal, usePagination } from 'hooks';
import { useCreatives } from '../api/getCreatives';
import CreativePreviewPopup from './CreativePreviewPopup';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useCreativeQueryParams from '../hooks/useCreativeQueryParams';
import { DEFAULT_PER_PAGE } from '../constants';

const ROWS_PER_PAGE_OPTIONS = [15, 25, 50];

const CreativeTable = () => {
  const { page, perPage, handlePageChange, handleRowsPerPageChange } = usePagination([], DEFAULT_PER_PAGE);
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState<number>(-1);
  const { setValue } = useFormContext();

  const creativeQueryParams = useCreativeQueryParams();
  const { data } = useCreatives({
    params: creativeQueryParams,
  });

  const { isOpen: isPreviewOpen, close, open } = useModal();

  const handleOpenPreview = useCallback(
    (index: number) => {
      setCurrentPreviewIndex(index);
      open();
    },
    [open]
  );

  useEffect(() => {
    setValue('creativesCount', data.count);
  }, [setValue, data.count]);

  return data.count ? (
    <>
      {isPreviewOpen && (
        <CreativePreviewPopup
          isOpen={isPreviewOpen}
          handleClose={close}
          creatives={data.data}
          currentPreviewIndex={currentPreviewIndex}
          setCurrentPreviewIndex={setCurrentPreviewIndex}
        />
      )}
      <TableContainer
        sx={{
          position: 'relative',
          height: '100%',
          mx: 'auto',
        }}
      >
        <Table
          sx={(t) => ({
            '&': {
              position: 'absolute',
              maxHeight: '100%',
              border: '1px solid',
              borderTop: 'none',
              borderRight: 'none',
              borderColor: t.palette.grey[200],
            },
            'tr th:first-of-type, td:first-of-type': {
              position: 'sticky',
              width: 64,
              left: 0,
              background: 'white',
              borderLeft: 'none',
            },
            'tr th:last-of-type, td:last-of-type': {
              position: 'sticky',
              width: 56,
              right: 0,
              background: 'white',
              borderRightWidth: '1px',
              borderRightStyle: 'solid',
              borderRightColor: t.palette.grey[200],
            },
          })}
          stickyHeader
          size="small"
        >
          <CreativeTableHead />
          <TableBody
            sx={(t) => ({
              'tr td': { borderBottom: 'none', borderRight: 'none' },
              'tr:first-of-type td': { borderTop: 'none' },
              'tr td:first-of-type': {
                borderRight: `4px solid ${t.palette.grey[200]} !important`,
              },
              'tr td:last-of-type': {
                borderLeft: `4px solid ${t.palette.grey[200]} !important`,
              },
            })}
          >
            {data.data.map((cr, index) => (
              <CreativeTableRow openPreview={handleOpenPreview} index={index} key={cr.id} item={cr} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          zIndex: 4,
          height: 52,
          mb: 2,
          width: '100%',
          py: 2,
          background: 'white',
          overflow: 'visible',
        }}
        id="creatives-pagination"
        labelRowsPerPage="Creatives per page:"
        count={data.count}
        page={page}
        component="div"
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      />
    </>
  ) : (
    <Typography variant="h1" sx={(t) => ({ color: t.palette.action.disabled, whiteSpace: 'nowrap' })}>
      {'No results'}
    </Typography>
  );
};

export default CreativeTable;
